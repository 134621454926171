class BtnCollapsible {
    constructor() {
        this.collapsible = document.querySelectorAll("[data-collapsible]");
        this.dropdownBtns = document.querySelectorAll(".collapsible__wrapper [role='button']");
        this.links = document.querySelectorAll(".collapsible__list a[target='_self']");
        this.isKeyboardUser = false;
        this.isExpanded = false;
        this.init();
    }

    ariaUpdates(isExpanded) {
        this.dropdownBtns.forEach(dropdownBtn => {
            dropdownBtn.setAttribute("aria-expanded", isExpanded.toString());
        });
    }

    setTabindex() {
        if (this.collapsible[0] === undefined) {
            return;
        }
        const tabindex = this.collapsible[0].classList.contains("js-active") ? "0" : "-1";

        if (this.collapsible[0].classList.contains("js-active")) {
            this.links.forEach(link => {
                link.setAttribute("tabindex", 0);
            });
        } else {
            this.links.forEach(link => {
                link.setAttribute("tabindex", -1);
            });
        }
        this.links.forEach(link => {
            link.setAttribute("tabindex", tabindex);
        });
    }

    closeMenu() {
        this.collapsible.forEach(button => {
            button.classList.remove("js-active");
        });
        this.links.forEach(link => {
            link.setAttribute("tabindex", -1);
        });
    }

    menuState() {
        document.addEventListener("focusout", () => {
            setTimeout(() => {
                if (this.isKeyboardUser) {
                    const anyLinkHasFocus = Array.from(this.links).some(link => document.activeElement === link);
                    if (!anyLinkHasFocus) {
                        this.isExpanded = false;
                        this.ariaUpdates(this.isExpanded);
                        this.closeMenu();
                    }
                }
            }, 0);
        });
    }

    handleKeyDown(event, node) {
        const isMenuOpen = node.classList.contains("js-active");

        this.collapsible.forEach(node => {
            node.addEventListener("keydown", event => {
                if (event.key === "Escape") {
                    this.closeMenu();
                    this.isExpanded = false;
                    this.ariaUpdates(this.isExpanded);
                }
            });
        });

        switch (event.key) {
            case "Escape":
                if (isMenuOpen) {
                    node.classList.remove("js-active");
                    this.isExpanded = false;
                    this.ariaUpdates(this.isExpanded);
                    this.links.forEach(link => {
                        link.setAttribute("tabindex", -1);
                    });
                }

                break;
            case " ":
            case "Enter":
                event.preventDefault();
                node.classList.toggle("js-active");
                this.isExpanded = true;
                this.ariaUpdates(this.isExpanded);
                this.links.forEach(link => {
                    link.setAttribute("tabindex", 0);
                });
                break;
            default:
                break;
        }
    }

    init() {
        this.setTabindex();
        this.menuState();
        // Detect when the user starts using the keyboard
        document.addEventListener("keydown", event => {
            if (event.key === "Tab") {
                this.isKeyboardUser = true;
            }
        });

        // Detect when the user clicks, indicating they are no longer using the keyboard
        document.addEventListener("mousedown", () => {
            this.isKeyboardUser = false;
        });

        this.collapsible.forEach(node => {
            //this line is here because DTC and HCP share HTML
            node.querySelector(".collapsible__content").removeAttribute("inert");

            const btn = node.querySelector(".collapsible__btn");
            btn.addEventListener("click", e => {
                e.preventDefault();
                node.classList.toggle("js-active");
            });

            btn.addEventListener("keydown", e => this.handleKeyDown(e, node));
        });
    }
}

export default BtnCollapsible;
